input, select, textarea, button, div, a {
  &:focus, &:active {
    outline: none;
  }
}

.jodit_workplace ol, .jodit_workplace ul {
  list-style: revert;
  margin: auto;
  padding: revert;
}